<template>
    <main class="main-page">
        <template v-if="showHeader">
            <section class="page-section">
                <div class="container">
                    <div class="grid mb-3 card nice-shadow-6 justify-content-between align-items-center">
                        <div class="col ">
                            <div class="">
                                <div class="flex align-items-center ">
                                    <div class="mr-3">
                                        <Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
                                            class="p-button-sm p-button-rounded px-4" />
                                    </div>
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Manage App</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section ">
            <div class="container">
                <div class="grid ">
                    <div class="md:col-12 sm:col-12 comp-grid">
                        <div>
                            <template v-if="pageReady">
                                <form ref="observer" tag="form" @submit.prevent="submitForm()"
                                    :class="{ 'card': !isSubPage }" class="">
                                    <!--[form-content-start]-->
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Hostel Name *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlhostel_name" v-model.trim="formData.hostel_name"
                                                        label="Hostel Name" type="text" placeholder="Enter Hostel Name"
                                                        class=" w-full" :class="getErrorClass('hostel_name')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('hostel_name')" class="p-error">{{
                                                        getFieldError('hostel_name') }}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Terms Of Use
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <div class="mb-3" :class="getErrorClass('terms_of_use')">
                                                        <Editor label="Terms Of Use" ref="ctrlterms_of_use"
                                                            placeholder="Enter Terms Of Use"
                                                            v-model="formData.terms_of_use" editorStyle="height: 120px">
                                                        </Editor>
                                                        <!-- <small class="p-3 text-negative" v-if="invalid && validated">{{errors[0]}}</small> -->
                                                    </div>
                                                    <small v-if="isFieldValid('terms_of_use')" class="p-error">{{
                                                        getFieldError('terms_of_use') }}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Bank Name *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlbank_name" v-model.trim="formData.bank_name"
                                                        label="Bank Name" type="text" placeholder="Enter Bank Name"
                                                        class=" w-full" :class="getErrorClass('bank_name')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('bank_name')" class="p-error">{{
                                                        getFieldError('bank_name') }}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Bank Account Number *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlbank_account_number"
                                                        v-model.trim="formData.bank_account_number"
                                                        label="Bank Account Number" type="text"
                                                        placeholder="Enter Bank Account Number" class=" w-full"
                                                        :class="getErrorClass('bank_account_number')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('bank_account_number')" class="p-error">{{
                                                        getFieldError('bank_account_number') }}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Bank Branch
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlbank_branch" v-model.trim="formData.bank_branch"
                                                        label="Bank Branch" type="text" placeholder="Enter Bank Branch"
                                                        class=" w-full" :class="getErrorClass('bank_branch')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('bank_branch')" class="p-error">{{
                                                        getFieldError('bank_branch') }}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Payment Instructions *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                <Editor label="Payment Instructions" ref="ctrlpayment_instructions"
                                                            placeholder="Enter Payment Instructions"
                                                            v-model="formData.payment_instructions" editorStyle="height: 120px">
                                                        </Editor>
                                                    <small v-if="isFieldValid('payment_instructions')"
                                                        class="p-error">{{ getFieldError('payment_instructions')
                                                        }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--[form-content-end]-->
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </template>
                            <template v-if="loading">
                                <div class="p-3 text-center">
                                    <ProgressSpinner style="width:50px;height:50px" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
    import { computed, reactive, toRefs, onMounted } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import { required } from '@/services/validators';
    import { useApp } from '@/composables/app.js';
    import { useEditPage } from '@/composables/editpage.js';
    const props = defineProps({
        id: [String, Number],
        pageName: {
            type: String,
            default: 'info',
        },
        routeName: {
            type: String,
            default: 'infoedit',
        },
        pagePath: {
            type: String,
            default: 'info/edit',
        },
        apiPath: {
            type: String,
            default: 'info/edit',
        },
        submitButtonLabel: {
            type: String,
            default: "Update",
        },
        formValidationError: {
            type: String,
            default: "Form is invalid",
        },
        formValidationMsg: {
            type: String,
            default: "Please complete the form",
        },
        msgTitle: {
            type: String,
            default: 'Update Record',
        },
        msgBeforeSave: {
            type: String,
            default: "",
        },
        msgAfterSave: {
            type: String,
            default: "Record updated successfully",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showSubmitButton: {
            type: Boolean,
            default: true,
        },
        redirect: {
            type: Boolean,
            default: true,
        },
        isSubPage: {
            type: Boolean,
            default: false,
        },
    });
    const app = useApp();
    const formDefaultValues = Object.assign({
        hostel_name: "",
        terms_of_use: "",
        bank_name: "",
        bank_account_number: "",
        bank_branch: "",
        payment_instructions: "",
    }, props.pageData);
    const formData = reactive({ ...formDefaultValues });
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if (props.redirect) {
            app.navigateTo(`/`);
        }
    }
    const rules = computed(() => {
        return {
            hostel_name: { required },
            terms_of_use: {},
            bank_name: { required },
            bank_account_number: { required },
            bank_branch: {},
            payment_instructions: { required }
        }
    });
    const v$ = useVuelidate(rules, formData); //form validation
    const page = useEditPage({ props, formData, v$, afterSubmit });
    const { submitted, saving, loading, pageReady } = toRefs(page.state);
    const { apiUrl, currentRecord } = page.computedProps;
    const { load, submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
    onMounted(() => {
        const pageTitle = "Edit Info";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
        load();
    });
</script>
<style scoped>
</style>